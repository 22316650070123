@import '@appkit4/styles/themes/appkit.orange.min.css';
@import '@appkit4/styles/scss/variables';
@import '@appkit4/react-components/dist/styles/appkit4-react.min.css';
@import 'react-multi-email/dist/style.css';

body {
    background-color: #F7F7F7 !important;
}

img {
    width: 100%;
}

h2 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
}

.ap-container {
    max-width: 100%;
    height: auto;
}

.ap-container:not(.g-0):not(.gx-0) {
    --ap-gutter-x: 1rem;
}

.padding {
    padding: 10px;
}

.home-panel .ap-panel-body {
    padding: 0;
}

.banner {
    width: 80% !important;
    float: right;
}

.page-loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 30px;
}

.back-button {
  padding-left: 0;
}

.footer-padding {
  padding-top: 100px;
}

.title {
    text-align: left;
    color: white;
    font-weight: normal;
    margin-top: 15%;
    font-size: 42px;
}

.banner-background { 
    background-color: #D04A02; 
}

.banner-image {
    padding-left: 0 !important;
    padding-right: 0 !important;
        background-color: #D04A02; 

}

.container, .container-md, .container-sm {
    max-width: 100% !important;
}

h3 {
    color: #D04A02;
    margin-top: 20px !important;
    margin-bottom: 10px !important;
}

.indent1 {
    margin-left: 20px;
}

.indent2 {
    margin-left: 40px;
}

.indent3 {
    margin-left: 60px;
}

.indent4 {
    margin-left: 80px;
}

.ap-tabset-container .ap-tabset-toggle-wrapper.underline {
    margin-bottom: 20px;
}

.ap-footer-content {
    text-align: center;
}

.right {
    float: right;
}

.inputNumberArrow {
    display: none;
}

.ap-footer-content {
    text-align: left;
    margin-left: 10px;
}

p {
    margin-bottom: 20px;
}

.ap-tabset-content {
    margin-top: 20px;
}

.survey-table {
    width: 100%;
}